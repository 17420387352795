@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  user-select: none;
}

::cue {
  font-size: 20pt;
}